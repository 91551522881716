import React from 'react';
import Image from 'next/image';
import { Link as ScrollLink } from 'react-scroll';
import { Button } from '@makeship/core';
import styled from 'styled-components';
import posthog from 'posthog-js';
import { H3, P1, Caption } from '../../../Typography';

const MastheadWrapper = styled.div`
  background-color: #f7f4fc;
  padding: 128px 24px 32px;
`;

const Masthead = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
`;

const MastheadHeading = styled(H3)`
  color: ${({ theme }) => theme.colors.primary};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 24px;
    line-height: 31px;
  }
`;

const MastheadBody = styled(P1)`
  margin: 24px 0 32px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    font-size: 16px;
    margin: 16px 0 32px;
  }
`;

const MastheadScroll = styled.div`
  margin-top: 48px;
  cursor: pointer;

  * + * {
    padding-bottom: 48px;
  }
`;

const RoundedPrimaryButton = styled(Button.Primary)`
  border-radius: 30px;
  margin-bottom: 64px;
`;

const GreatPlaceToWorkImageMobileWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    align-self: center;
    height: auto;
    margin: 48px 0 16px;
    gap: 16px;
  }
`;

const CareerMasthead: React.FC = () => (
  <MastheadWrapper>
    <Masthead>
      <MastheadHeading>Connecting communities by bringing content to life!</MastheadHeading>
      <MastheadBody>
        We&apos;re creating a platform for Creators to rapidly launch exciting custom products their Fans love - and
        make a living out of it..
      </MastheadBody>
      <ScrollLink
        to="openJobs"
        smooth
        duration={2000}
        onClick={() => posthog.capture('careers_viewPositionsCTA_click')}
      >
        <RoundedPrimaryButton>View Open Positions</RoundedPrimaryButton>
      </ScrollLink>
      <div>
        <ScrollLink to="mission" smooth duration={500} onClick={() => posthog.capture('careers_scrollButton_click')}>
          <MastheadScroll>
            <Caption>Scroll Down</Caption>
            <Image src="/assets/icons/scroll-down.svg" width="16" height="16" quality={90} />
          </MastheadScroll>
        </ScrollLink>
      </div>
      <GreatPlaceToWorkImageMobileWrapper>
        <Image
          src="/assets/careers/careers-great-place-to-work-2024.png"
          width={120}
          height={204}
          alt="great place to work badge from 2024"
          priority
        />
        <Image
          src="/assets/careers/careers-great-place-to-work-2023.png"
          width={120}
          height={204}
          alt="great place to work badge from 2023"
          priority
        />
      </GreatPlaceToWorkImageMobileWrapper>
    </Masthead>
  </MastheadWrapper>
);

export default CareerMasthead;
